<template>
    <div>
        <q-layout>
            <!-- <q-ajax-bar
            ref="bar"
            position="top"
            color="accent"
            size="2px"
            skip-hijack
        />
     -->
            <HomeNav></HomeNav>
            <q-page-container>
                <q-page>
                    <slot></slot>
                </q-page>
            </q-page-container>

            <q-footer elevated class="footer tw-mt-10 lg:tw-mt-20">
                <HomeFooter></HomeFooter>
            </q-footer>
            <ButtonInfoContact></ButtonInfoContact>
        </q-layout>
    </div>
</template>
<script lang="ts" setup>
import HomeNav from "~/components/HomeNav.vue"
import { useQuasar, QAjaxBar } from "quasar"
import HomeFooter from "~/components/HomeFooter.vue"
import ButtonInfoContact from "~/components/ButtonInfoContact.vue"

const $q = useQuasar()
const bar = ref<QAjaxBar | null>(null)

const flash = ref({
    notification: {
        id: "",
        type: "",
        msg: "",
        html: false,
    },
})

const initSettingsStore = useDefaultSettingsStore()
const shopListStore = useShopListStore()

let timer: NodeJS.Timeout

// watch(() => flash.value.notification, value => {

//   if (value) {
//     $q.notify({
//       type: value.type,
//       message: value.msg,
//       html: value.html,
//       timeout: notificationTimeout(value.msg)
//     })

//     //This is by prevent duplicate notifications
//     flash.value.notification = {
//       id: '',
//       type: '',
//       msg: '',
//       html: false
//     }
//   }

// }, {deep: true})

onMounted(async () => {
    try {
        const resource = shopListStore.currentShop
            ? `init-settings/store/${shopListStore.currentShop.id}`
            : "init-settings"
        const { data } = await useOFetch("GET", resource)
        initSettingsStore.setSettings(data.data)
    } catch (error) {}
    // const notification = flash.value.notification

    // if (notification) {

    //   $q.notify({
    //     type: notification.type,
    //     message: notification.msg,
    //     html: notification.html,
    //     timeout: notificationTimeout(notification.msg)
    //   })
    // }

    // router.on('start', () => {

    //   $q.loading.show({
    //   delay:150,
    //   spinnerColor:'primary'
    //   })
    //   timer=setTimeout(()=>{
    //     $q.loading.hide()
    //     clearTimeout(timer)
    //   },timeClearSpinner)
    // })

    // router.on('finish', () => {

    //     $q.loading.hide()
    //     clearTimeout(timer)
    // })
})

function notificationTimeout(msg: string): number {
    return msg.length > 100 ? 10000 : 5000
}
</script>
