<script setup lang="ts">
const props = defineProps({
    contacts: {
        type: Object,
        required: true,
        default: () => {
            whatsapp: ""
        },
    },
})
const router = useRouter()

function email() {
    // const correoURL = `mailto:${usePage().props.contacts.email}`;
    // window.open(correoURL);
    router.push("/contact")
}

function whatsapp() {
    const whatsappURL = props.contacts.whatsapp
    window.open(whatsappURL)
}
</script>

<template>
    <div class="tw-fixed tw-bottom-10 tw-right-10 tw-self-end tw-z-10">
        <q-fab
            vertical-actions-align="right"
            color="primary"
            glossy
            icon="comment"
            direction="up"
            class="tw-z-50"
        >
            <q-fab-action
                label-position="left"
                color="primary"
                @click="email"
                icon="email"
            />
            <q-fab-action
                label-position="left"
                color="secondary"
                @click="whatsapp"
                icon="fa-brands fa-whatsapp"
            />
        </q-fab>
    </div>
    <!-- <q-page-sticky position="bottom-right" :offset="[25, 25]">
  </q-page-sticky> -->
</template>
